<template lang="html">
  <div class="app-links">
    <div
      class="app-links__item"
      v-for="(item, i) in initialData.links"
      :key="i"
    >
      <a :href="item.link.url" :target="item.link.target">
        <img
          v-if="item.image"
          :src="item.image.sizes['medium_large']"
          :alt="item.image.alt"
        />

        <span>{{ item.link.title }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLinks',
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.app-links {
  display: flex;

  &__item {
    flex: 1;
    padding: 10px 30px;

    &:not(:first-child) {
      border-left: 1px solid $cream;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        margin-top: 10px;
      }
    }
  }
}
</style>
